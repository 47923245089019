import React from 'react';
import { useState, useRef, useEffect } from "react";
import './Navbar.css';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, Link } from 'react-router-dom';
import Stickynode from 'react-stickynode';

import { IconMail } from '@tabler/icons-react';
import { IconFileCv } from '@tabler/icons-react';
import { IconBrandInstagram } from '@tabler/icons-react';
import { IconLock } from '@tabler/icons-react';
import { IconChevronsUp  } from '@tabler/icons-react';



function Navbar() {
  const navigate = useNavigate();

  const [selected, setSelected] = useState('foto');
    
  const handleClick = (str:string) => {
      setSelected(str);
  }
  const [subselected0, setSubselected0] = useState('fotovse');
    
  const handleClick_sub0 = (str:string) => {
      setSubselected0(str);
  }

  const [subselected1, setSubselected1] = useState('designvse');
    
  const handleClick_sub1 = (str:string) => {
      setSubselected1(str);
  }

  const [subselected2, setSubselected2] = useState('kytaryvse');
    
  const handleClick_sub2 = (str:string) => {
      setSubselected2(str);
  }

  // States to track active status for each group
    const [isActiveChoices, setIsActiveChoices] = useState<boolean[]>([true, false, false, false]);
  
    // Refs for each header
    const activeSelectRefs = useRef<(HTMLParagraphElement | null)[]>([]);
  
    useEffect(() => {
      const observers: MutationObserver[] = [];
  
      // Create observers for each ref
      activeSelectRefs.current.forEach((ref, index) => {
        if (ref) {
          const observer = new MutationObserver(() => {
            const hasActiveSelectClass = ref.classList.contains("select-active");
            setIsActiveChoices((prev) => {
              const newChoices = [...prev];
              newChoices[index] = hasActiveSelectClass;
              return newChoices;
            });
          });
  
          observer.observe(ref, { attributes: true, attributeFilter: ["class"] });
          observers.push(observer);
        }
      });
  
      return () => {
        // Cleanup observers on unmount
        observers.forEach((observer) => observer.disconnect());
      };
    }, []); // Empty dependency array ensures this runs once on mount


    const [copySucc, setCopySucc] = useState(false)
    const CopyToClipboard = ()=>{
      navigator.clipboard.writeText("malchertomm@gmail.com");
      setCopySucc(true)
      setTimeout(() => {
          setCopySucc(false)
        }, 1500);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: smooth scrolling
    });
  };

  return (
    <section className="Navbar">
      <div className='mobile-wrap'>
        <div className="navbar-top">
            <img src="/assets/img/logo.png" alt="" className="navbar-img" onClick={() => window.location.href = 'https://tomasmalcher.cz'}/>

        </div>
        
        <div className="navbar-wrap">

              <div className="navbar-select">
                <p  onClick={()=>{handleClick('foto'); navigate('foto/'+subselected0)}} 
                    className={selected === 'foto'? 'select-active' : ''}
                    key={0}
                    ref={(el0) => {
                      activeSelectRefs.current[0] = el0;
                    }}
                    >
                    <p className="cursor-hover">Foto</p>
                </p>
                <div className={`navbar-subselect ${isActiveChoices[0] ? "show-active" : ""}`}>
                  <p onClick={()=>{handleClick_sub0('sport'); navigate('foto/sport')}} className={subselected0 === 'sport'? 'subselect-active' : ''}>Sport</p>
                  <p onClick={()=>{handleClick_sub0('street'); navigate('foto/street')}} className={subselected0 === 'street'? 'subselect-active' : ''}>Street</p>
                  <p onClick={()=>{handleClick_sub0('nature'); navigate('foto/nature')}} className={subselected0 === 'nature'? 'subselect-active' : ''}>Příroda</p>
                  <p onClick={()=>{handleClick_sub0('ostatni'); navigate('foto/ostatni')}} className={subselected0 === 'ostatni'? 'subselect-active' : ''}>Ostatní</p>
                  <p onClick={()=>{handleClick_sub0('fotovse'); navigate('foto/fotovse')}} className={subselected0 === 'fotovse'? 'subselect-active' : ''}>Vše</p>
                </div>
              </div>

              <div className="navbar-select">
                <p onClick={()=>{handleClick('design'); navigate('design/'+subselected1)}} 
                  className={selected === 'design'? 'select-active' : ''}
                  key={1}
                  ref={(el1) => {
                    activeSelectRefs.current[1] = el1;
                  }}
                  >
                    <p className="cursor-hover">Design</p>
                </p>
                <div className={`navbar-subselect ${isActiveChoices[1] ? "show-active" : ""}`}>
                  <p onClick={()=>{handleClick_sub1('demigod'); navigate('design/demigod')}} className={subselected1 === 'demigod'? 'subselect-active' : ''}>Demigod</p>
                  <p onClick={()=>{handleClick_sub1('cwo'); navigate('design/cwo')}} className={subselected1 === 'cwo'? 'subselect-active' : ''}>CWO</p>
                  <p onClick={()=>{handleClick_sub1('ostatni_d'); navigate('design/ostatni_d')}} className={subselected1 === 'ostatni_d'? 'subselect-active' : ''}>Ostatní</p>
                  <p onClick={()=>{handleClick_sub1('designvse'); navigate('design/designvse')}} className={subselected1 === 'designvse'? 'subselect-active' : ''}>Vše</p>
                </div>
              </div>

              <div className="navbar-select">
                <p onClick={()=>{handleClick('kytary'); navigate('kytary/'+subselected2)}} 
                  className={selected === 'kytary'? 'select-active' : ''}
                  key={2}
                  ref={(el2) => {
                    activeSelectRefs.current[2] = el2;
                  }}
                  >
                    <p className="cursor-hover">Výroba kytar</p>
                </p>
                <div className={`navbar-subselect ${isActiveChoices[2] ? "show-active" : ""}`}>
                  <p onClick={()=>{handleClick_sub2('l17'); navigate('kytary/l17')}} className={subselected2 === 'l17'? 'subselect-active' : ''}>L-17</p>
                  <p onClick={()=>{handleClick_sub2('viking'); navigate('kytary/viking')}} className={subselected2 === 'viking'? 'subselect-active' : ''}>Viking</p>
                  <p onClick={()=>{handleClick_sub2('purple'); navigate('kytary/purple')}} className={subselected2 === 'purple'? 'subselect-active' : ''}>Purple</p>
                  <p onClick={()=>{handleClick_sub2('floyd'); navigate('kytary/floyd')}} className={subselected2 === 'floyd'? 'subselect-active' : ''}>Floyd</p>
                  <p onClick={()=>{handleClick_sub2('kytaryvse'); navigate('kytary/kytaryvse')}} className={subselected2 === 'kytaryvse'? 'subselect-active' : ''}>Vše</p>
                </div>
              </div>

              <div className="navbar-select">
                <p onClick={()=>{handleClick('video'); navigate('video/video')}} 
                  className={selected === 'video'? 'select-active' : ''}>
                    <p className="cursor-hover">Video</p>
                </p>
              </div>
        </div>


        <div className='navbar-bottom'>
            <div className='bottom-wrap'>
              <div className="navbar-select">
                <p onClick={()=>{handleClick('about'); navigate('omne/omne')}} 
                  className={selected === 'about'? 'select-active' : ''}>
                    <p className="cursor-hover">O mně</p>
                </p>
              </div>
              <div className="navbar-select">
                <div className='lock-mobile' onClick={()=>{handleClick('lock'); navigate('lock/pass')}}  >
                  <IconLock width={32} height={32} className="navbar-icon" />
                </div>
              </div>
                <div className='all-icons'>
                
                  <div className="CopyToClipboard">
                  <div className="icon-wrap">
                  <Tooltip
                    className="mail-copy"
                    title={copySucc ? `Zkopírováno ${'\u2713'}` : "malchertomm@gmail.com"}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [40, -100],
                            },
                          },
                        ],
                      },
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#333',
                          color: '#fff',           
                          fontSize: '14px',       
                          borderRadius: '8px',    
                          padding: '8px 12px',   
                          font: "Cormorant Upright"  
                        },
                      },
                    }}
                  >
                    
                      <IconMail width={32} height={32} className="navbar-icon" onClick={CopyToClipboard} /> 
                    
                  </Tooltip>
                    </div>
                    </div>

                    <div className="CopyToClipboard">
                  <div className="icon-wrap">
                  <Tooltip
                    className="mail-copy"
                    title={"CV - Životopis"}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [10, -100],
                            },
                          },
                        ],
                      },
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#333',
                          color: '#fff',           
                          fontSize: '14px',       
                          borderRadius: '8px',    
                          padding: '8px 12px',   
                          font: "Cormorant Upright"  
                        },
                      },
                    }}
                  >
                    
                    <Link to="./download/CV_Tomas_Malcher.pdf" target="_blank" download>                
                      <IconFileCv width={32} height={32} className="navbar-icon" onClick={CopyToClipboard} /> 
                    </Link>
                    
                  </Tooltip>
                    </div>
                    </div>

                  
                  <a href="https://www.instagram.com/tommalcher.photo/" target="_blank" rel="noopener noreferrer">
                    <IconBrandInstagram width={32} height={32} className="navbar-icon" />
                  </a>
                  <div className='lock-icon' onClick={()=>{handleClick('lock'); navigate('lock/pass')}}  >
                    <IconLock width={32} height={32} className="navbar-icon" />
                  </div>
                </div>
                <div className='copyrignt-desktop'>
                  <p>© 2025 Tomáš Malcher</p>
                </div>
              </div>
        </div>
        
      </div>
      <Stickynode enabled={true} top={0}>       
        <div className='subselects-mobile'>
            <div className={`navbar-subselect-m ${isActiveChoices[0] ? "show-active-m" : ""}`}>
                  <p onClick={()=>{handleClick_sub0('sport'); navigate('foto/sport')}} className={subselected0 === 'sport'? 'subselect-active' : ''}>Sport</p>
                  <p onClick={()=>{handleClick_sub0('street'); navigate('foto/street')}} className={subselected0 === 'street'? 'subselect-active' : ''}>Street</p>
                  <p onClick={()=>{handleClick_sub0('nature'); navigate('foto/nature')}} className={subselected0 === 'nature'? 'subselect-active' : ''}>Příroda</p>
                  <p onClick={()=>{handleClick_sub0('ostatni'); navigate('foto/ostatni')}} className={subselected0 === 'ostatni'? 'subselect-active' : ''}>Ostatní</p>
                  <p onClick={()=>{handleClick_sub0('fotovse'); navigate('foto/fotovse')}} className={subselected0 === 'fotovse'? 'subselect-active' : ''}>Vše</p>
            </div>
            <div className={`navbar-subselect-m ${isActiveChoices[1] ? "show-active-m" : ""}`}>
                  <p onClick={()=>{handleClick_sub1('demigod'); navigate('design/demigod')}} className={subselected1 === 'demigod'? 'subselect-active' : ''}>Demigod</p> 
                  <p onClick={()=>{handleClick_sub1('cwo'); navigate('design/cwo')}} className={subselected1 === 'cwo'? 'subselect-active' : ''}>CWO</p>
                  <p onClick={()=>{handleClick_sub1('ostatni_d'); navigate('design/ostatni_d')}} className={subselected1 === 'ostatni_d'? 'subselect-active' : ''}>Ostatní</p>
                  <p onClick={()=>{handleClick_sub1('designvse'); navigate('design/designvse')}} className={subselected1 === 'designvse'? 'subselect-active' : ''}>Vše</p>
            </div>
            <div className={`navbar-subselect-m ${isActiveChoices[2] ? "show-active-m" : ""}`}>
                  <p onClick={()=>{handleClick_sub2('l17'); navigate('kytary/l17')}} className={subselected2 === 'l17'? 'subselect-active' : ''}>L-17</p>
                  <p onClick={()=>{handleClick_sub2('viking'); navigate('kytary/viking')}} className={subselected2 === 'viking'? 'subselect-active' : ''}>Viking</p>
                  <p onClick={()=>{handleClick_sub2('purple'); navigate('kytary/purple')}} className={subselected2 === 'purple'? 'subselect-active' : ''}>Purple</p>
                  <p onClick={()=>{handleClick_sub2('floyd'); navigate('kytary/floyd')}} className={subselected2 === 'floyd'? 'subselect-active' : ''}>Floyd</p>
                  <p onClick={()=>{handleClick_sub2('kytaryvse'); navigate('kytary/kytaryvse')}} className={subselected2 === 'kytaryvse'? 'subselect-active' : ''}>Vše</p>
            </div>
            <IconChevronsUp  stroke={1.5} width={50} height={50} color='black' className='scrollup-icon' onClick={scrollToTop}/>    
        </div>
        </Stickynode>      
    </section>
  );
}

export default Navbar;