import React, { useState, useEffect } from "react";
import { TextField, Button} from "@mui/material";
import { IconArrowRight } from "@tabler/icons-react";
import CryptoJS from "crypto-js";

import MasonryGallery from "../MasonryGallery/MasonryGallery.tsx";

import "./LockGallery.css";

interface Photo {
  src: string;
  thumbnail: string;
  alt: string;
}

interface GalleryProps {
  toggleScrollLock: (locked: boolean) => void;
}

const LockGallery: React.FC<GalleryProps> = ({ toggleScrollLock }) => {
  const [password, setPassword] = useState("");
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [showGallery, setShowGallery] = useState(false);
  const [folders, setFolders] = useState<string[]>([]);
  const [, setSelectedGallery] = useState<string | null>(null);
  const [listFoto, setListFoto] = useState<Photo[]>([]);
  const [galleryPreviews, setGalleryPreviews] = useState<{ [key: string]: string }>({});
  const [lowResPreviews, setLowResPreviews] = useState<{ [key: string]: string }>({});

  // 🔐 Hashed password
  const correctPasswordHash =
    "a665a45920422f9d417e4867efdc4fb8a04a1f3fff1fa07e998e86f7f7a27ae3"; // Hash of "password"

  useEffect(() => {
    const fetchPreviews = async () => {
      const previewImages: { [key: string]: string } = {};
      const placeholderImages: { [key: string]: string } = {};

      try {
        // 🔹 Fetch gallery index
        const indexResponse = await fetch("/assets/lockGallery/index.json");
        const { folders } = await indexResponse.json();
        setFolders(folders);

        // 🔹 Fetch all gallery JSONs in parallel
        const galleryPromises = folders.map(async (folder) => {
          try {
            const res = await fetch(`/assets/lockGallery/${folder}.json`);
            const photos: Photo[] = await res.json();

            if (photos.length > 0) {
              const randomPhoto = photos[Math.floor(Math.random() * photos.length)];
              placeholderImages[folder] = randomPhoto.thumbnail.replace("thumbnail", "lowres"); // Low-res placeholder
              previewImages[folder] = randomPhoto.thumbnail; // Actual high-res thumbnail
            }
          } catch (error) {
            console.error(`Error loading preview for ${folder}:`, error);
          }
        });

        await Promise.all(galleryPromises);

        // 🔄 Batch update state
        setLowResPreviews(placeholderImages); // Show low-res placeholders first
        setTimeout(() => setGalleryPreviews(previewImages), 1000); // Delay high-res images
      } catch (error) {
        console.error("Error fetching gallery index:", error);
      }
    };

    fetchPreviews();
  }, []);

  const handleCheckPassword = () => {
    const hashedPassword = CryptoJS.SHA256(password).toString();
    setIsCorrect(hashedPassword === correctPasswordHash);
  };

  const loadGallery = (galleryName: string) => {
    fetch(`/assets/lockGallery/${galleryName}.json`)
      .then((res) => res.json())
      .then((data) => {
        setListFoto(data);
        setSelectedGallery(galleryName);
        setShowGallery(true);
      })
      .catch((err) => console.error(`Error loading gallery ${galleryName}:`, err));
  };

  return (
    <section className="LockGallery">
      {isCorrect ? (
        showGallery ? (
          <div className="lock-gallery-show">
            <div className="back-button" onClick={() => setShowGallery(false)} style={{ marginBottom: "10px" }}>
              <p className="back-button-text">⬅ Zpět</p>
            </div>
            <MasonryGallery photos={listFoto} toggleScrollLock={toggleScrollLock} />
          </div>
        ) : (
          <div className="lock-gallery-wrap">
            <div className="lock-gallery-heading">Vyber galerii:</div>
            <div className="lock-gallery-grid">
              {folders.map((folder) => (
                <div
                  key={folder}
                  className="lock-card"
                  onClick={() => loadGallery(folder)}
                  style={{
                    backgroundImage: `url(${galleryPreviews[folder] || lowResPreviews[folder] || "default-placeholder.jpg"})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    transition: "background-image 0.5s ease-in-out",
                  }}
                >
                  <p className="lock-name">{folder}</p>
                </div>
              ))}
            </div>
          </div>
        )
      ) : (
        <div>
          <form  className="lock-form">
            <TextField
              label="Heslo:"
              type="password"
              variant="standard"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={isCorrect === false}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: isCorrect ? "green" : isCorrect === false ? "red" : undefined,
                  },
                },
              }}
            />
            <Button type="submit" variant="contained" color="primary" onClick={handleCheckPassword} style={{ marginTop: "10px" }}>
              <IconArrowRight stroke={2} />
            </Button>
          </form>
        </div>
      )}
    </section>
  );
};

export default LockGallery;
